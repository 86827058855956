@import "tailwindcss/base";
@import "trix";

trix-editor h2 {
  @apply text-base font-medium;
}

.trix-content {
  a {
    @apply underline font-medium;
  }
  ul {
    @apply list-disc;
  }
}

.trix-content ol {
  @apply list-decimal;
}

@import '@beyonk/gdpr-cookie-consent-banner/dist/style.css';

input[type="checkbox"] {
  @apply appearance-none h-3.5 w-3.5 border-gray-400 border rounded checked:border-none checked:bg-primary bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer
}
input[type="checkbox"].search {
  @apply h-6 w-6 md:h-4 md:w-4 transition-all duration-200;
}


input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.bg-progressive-gradient {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 70%, rgba(0,0,0,0.7) 95%);
}

.badge {
  @apply px-3 text-xs font-medium text-blue-700 bg-blue-300 rounded-lg;
}

.btn-primary {
  @apply bg-primary text-gray-50 font-semibold px-5 py-1.5 text-sm rounded-full cursor-pointer transition-opacity hover:bg-primary/90 transition-all;
}

.btn-secondary {
  @apply bg-secondary text-gray-50 font-semibold px-5 py-1.5 text-sm rounded-full cursor-pointer transition-opacity hover:bg-secondary/90;
}

.btn-link {
  @apply underline leading-relaxed text-xs text-gray-600 font-medium cursor-pointer bg-transparent;
}

.btn {
  @apply border border-gray-100 rounded-full text-xs text-gray-600 font-semibold cursor-pointer py-1.5 px-3 bg-gray-100 hover:bg-gray-50 w-36 text-center transition-all;
}

.pill-blue {
  @apply bg-blue-100 text-blue-700 font-medium px-3 py-1.5 rounded-full text-xs;
}
.pill-green {
  @apply px-3 text-xs font-medium text-green-800 bg-green-200 rounded-lg py-px;
}

.pill-yellow {
  @apply px-3 text-xs font-medium text-yellow-800 bg-yellow-300 rounded-lg py-px;
}

.pill-red {
  @apply px-3 text-xs font-medium text-red-800 bg-red-200 rounded-lg py-px;
}

.menu-item {
  @apply font-medium flex my-2 flex items-center hover:bg-gray-100 py-1 px-3 rounded-full;
}

.label-base {
  @apply text-sm text-gray-500 font-semibold;
}

.table-header {
  @apply text-xs text-gray-500 font-semibold tracking-wide uppercase;
}

.input-base {
  @apply border w-full sm:w-auto border-gray-200 px-4 py-2 rounded-lg text-base sm:text-sm focus:outline-primary;
}

input[type="text"]{
  // prevent sfari mobile inner shadow
  -webkit-appearance: none;
}

.profile-page {
  @apply container mx-auto my-0 md:my-6 sm:w-4/5;
}

.profile-content-section {
  @apply px-2 xl:px-0;
}

.title {
  @apply font-bold text-sm sm:text-base md:text-xl text-gray-700 md:mb-4 mb-2 py-4 px-2 xl:px-0 border-b border-gray-200 sticky top-0 bg-white/90 z-10;
}

.subtitle {
  @apply font-semibold text-gray-700 my-8 pb-2 border-b border-gray-200;
}

.explainer {
  @apply text-sm text-gray-500 font-medium mb-4 leading-relaxed;
}

.divider {
  @apply w-full h-px bg-gray-200 my-4;
}

.tag {
  @apply bg-secondary text-xs font-bold text-white rounded-full px-2;
}

.list-content {
  h2 {
    @apply font-semibold mt-2 mb-1 text-primary;
  }

  a {
    @apply font-semibold underline;
  }
}

.admin-list-header {
  @apply sticky hidden bg-gray-100 border-b py-4 rounded-t-lg top-12 lg:grid grid-cols-12;
}

.admin-list-container {
  @apply my-4 border rounded-lg;
}

.admin-search-form {
  @apply border p-4 rounded-lg;
}

.cookieConsentToggle * {
  fill: #006B52;
}

.cookieConsent {
  @apply max-w-6xl;
}

.cookieConsent__Button, .cookieConsent__Button--Close {
  @apply border border-gray-100 rounded-full text-xs text-white font-semibold cursor-pointer py-1.5 px-3 bg-secondary w-36 text-center transition-all;
}

button[type="button"].cookieConsent__Button {
  @apply border border-gray-200 rounded-full text-xs text-gray-700 font-semibold cursor-pointer py-1.5 px-3 bg-gray-300 w-36 text-center transition-all;
}

.cookieConsent__Button:hover {
  @apply opacity-95;
}

.cookieConsent__Description {
  @apply text-xs font-medium;
}

.cookieConsentOperations {
  @apply px-4;
}
.cookieConsentOperations__List {
  @apply rounded-xl text-gray-800 px-3 sm:px-6;
}

.cookieConsentOperations__Item label {
  @apply text-primary;
}

.cookieConsentOperations__ItemLabel {
  @apply text-xs sm:text-sm;
}

.cookieConsentWrapper {
  @apply bg-gray-800 bg-opacity-95;
}

.cookieConsentOperations__Item input + label::before {
  @apply cursor-pointer;
}

.cookieConsentOperations__Item input + label::after {
  @apply bg-gray-600;
}

.cookieConsentOperations__Item input:checked + label::after {
  @apply bg-secondary;
}

// Hacky: we hide the button to toggle cookie consent banner via css
// this allow us to invoke a click on it using js, and show the modal at will
.cookieConsentToggle {
  display: none;
}

input[type="radio"]:checked + label, input[type="radio"]:checked + label > div {
  @apply text-secondary;
}

.cookieConsent__Button--Close {
  @apply text-center mx-auto;
}

img {
  aspect-ratio: attr(width) / attr(height);
}

.flatpickr-calendar select {
  padding-right: 0.2rem !important;
}

.modal-checkout-selector .flatpickr-calendar {
  @apply shadow-none;
}
.modal-checkout-selector .flatpickr-input {
  @apply hidden;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23006B52' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2rem !important;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  height: 40px;
  line-height: 1.4rem;
}

.turbo-progress-bar {
  background-color: #006B52;
}

// Disabled since not supported in safari (not so old version that is still popular)
// We can re-enable later, when adoption is higher
#description {
  // background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 60px, rgba(0, 0, 0, 0) 100%);
  // background-clip: text;
  // color: transparent;
}

// don't display scrollbar for image gallery
#gallery-modal, .hide-scrollbar {
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (min-width: 1024px)  {
  .search-results-wrapper {
    max-height: calc(100vh - 50px)
  }
}

#messaging-wrapper {
  height: calc(100dvh - 220px)
}

@media (min-width: 1024px)  {
  #messaging-wrapper {
    height: calc(100dvh - 340px)
  }
}

#sidebar-container {
  height: calc(100dvh)
}

// override some default maplibre styles (to display map and markers)
.mapboxgl-popup-content, .maplibregl-popup-content {
  padding: 0 !important;
}

.maplibregl-popup-tip.mapboxgl-popup-tip {
  display: none;
}

.maplibregl-popup-content.mapboxgl-popup-content {
  @apply rounded-2xl;
}

.mapboxgl-popup {
  @apply z-20;
}

// override default for the svelte locaiton autocomplete
.autocomplete {
  height: auto !important;
  display: block !important;
}

.autocomplete-list {
  position: absolute !important;
  margin-top: 2.8rem !important;
  text-align: left !important;
}

.autocomplete-input {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 2.5rem !important;
  padding-right: 1rem !important;
  font-size: 1rem !important;
  height: auto !important;
}

@media (max-width: 640px)  {
  .autocomplete {
    width: 100%;
  }
  .autocomplete-input {
    width: 100% !important;
  }

  .filter-container {
    height: calc(var(--vh, 1vh) * 100 - 2rem);
  }
}

@media (min-width: 768px)  {
  .autocomplete-input {
    font-size: 0.875rem !important;
  }
}

.autocomplete-list-item.confirmed {
  background-color: #fff !important;
}
.autocomplete-list-item.selected {
  background-color: #006B52 !important;
}

.autocomplete-list-item.selected div {
  color: #fff !important;
}

.autocomplete-list-item.selected div span {
  color: #fff !important;
}

// override accent color for the flatpickr calendar
// all variations listed here were simply taken by looking at the rendered css from the plugin
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background-color: #006B52 !important;
  border-color: #006B52 !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #006B52;
}

.flatpickr-day.hidden {
  display: block !important;
}

.flatpickr-current-month span.cur-month:first-letter {
  @apply uppercase;
}

.flatpickr-current-month span.cur-month {
  @apply font-medium text-base;
}

.flatpickr-calendar, .flatpickr-innerContainer, .flatpickr-days {
  @apply overflow-visible;
}

.flatpickr-day {
  @apply font-medium text-gray-900;
}

.flatpickr-day.no-start {
  color: #6b7280 !important;
  text-decoration-line: none !important;
}

.flatpickr-day.min-stay, .flatpickr-day.sat-to-sat {
  color: #6b7280 !important;
}

.flatpickr-day.min-stay:hover, .flatpickr-day.sat-to-sat:hover {
  @apply border-gray-300;
}

.flatpickr-day.min-stay:hover::before {
  content: attr(data-min-stay) ' nuits minimum';
  @apply absolute -top-2 left-6 border z-50 -translate-y-2 shadow-lg leading-normal py-1 rounded-xl w-32 bg-white text-[11px];
}

.flatpickr-day.sat-to-sat:hover::before {
  content: 'Samedi au samedi';
  @apply absolute -top-2 left-6 border z-30 -translate-y-2 shadow-lg leading-normal py-1 rounded-xl w-32 bg-white text-[11px];
}

.flatpickr-days .dayContainer:first-child {
  @apply z-20;
}


.flatpickr-day.no-start:hover {
  @apply border-gray-300;
}

.flatpickr-day.no-start:not(.endRange):hover::before {
  content: 'Départ uniquement';
  @apply absolute -top-2 left-6 border z-30 -translate-y-2 shadow-lg leading-normal py-1 rounded-xl w-32 bg-white text-[11px];
}

.flatpickr-day.notAllowed {
  color: rgba(57,57,57,0.3) !important;
}

.flatpickr-day.endRange {
  color: #fff !important;
}


.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  @apply line-through text-gray-300;
}

// override phone country selector styling
.iti {
  @apply w-full;
}

.iti--separate-dial-code .iti__selected-dial-code, .iti__country-list {
  @apply text-sm;
}

@import 'flatpickr/dist/flatpickr.min.css';

@media (max-width: 640px) {
  #pricing-page {
    #pricing-component[data-active-tab="1"] {
      .pricing-row > div:not(:nth-child(2)) {
        @apply hidden;
      }
      > div button:nth-child(1) {
        @apply border-secondary;
      }
    }
    #pricing-component[data-active-tab="2"] {
      .pricing-row > div:not(:nth-child(3)) {
        @apply hidden;
      }
      > div button:nth-child(2) {
        @apply border-secondary;
      }
    }
    #pricing-component[data-active-tab="3"] {
      .pricing-row > div:not(:nth-child(4)) {
        @apply hidden;
      }
      > div button:nth-child(3) {
        @apply border-secondary;
      }
    }
    #pricing-component[data-active-tab="4"] {
      .pricing-row > div:not(:nth-child(5)) {
        @apply hidden;
      }
      > div button:nth-child(4) {
        @apply border-secondary;
      }
    }

    .pricing-row > div:nth-child(1) {
      display: flex !important;
    }
  }
}

html {
  scrollbar-gutter: stable;
}

// deal with iOS (and maybe other mobile devices) url bar
:root {
  --vh: 1vh;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

.mapboxgl-ctrl-attrib.mapboxgl-compact-show, .maplibregl-ctrl-attrib.maplibregl-compact-show {
  visibility: inherit;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;/* Hide scrollbar for Chrome, Safari and Opera */
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@import "tailwindcss/components";

@import "tailwindcss/utilities";
